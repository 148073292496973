
function Rheader(props) {
    console.log(props)
    return (

        
        <header>
            <h2>Welcome <strong>"{props.name}"</strong> to Asia's Largest Chemfest 2024 </h2>
        </header>

    );
}

export default Rheader;