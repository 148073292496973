import React from "react";
import  ReactDOM from "react-dom"; 
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App.js";
import RegCard from "./regCard.js"
import Rheader from "./elogo/Rheader.js";
import Rfooter from "./elogo/Rfooter.js";

ReactDOM.render(<BrowserRouter>
  <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/RegCard" element={<RegCard />}></Route>
      <Route path="/Rheader" element={<Rheader />}></Route>
      <Route path="/Rfooter" element={<Rfooter />}></Route>
      {/* <Route path="/STLogin" element={<STLogin />}></Route>
      <Route path="/DashBord" element={<DashBord />}></Route> */}
</Routes>
</BrowserRouter>
,
document.getElementById("root"))