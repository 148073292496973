
function Rfooter() {
    return (

        
        <header>
            <h2>Thank you for registering for VORTEX 11.0!  </h2>
        </header>

    );
}

export default Rfooter;