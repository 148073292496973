
import './style.css';
import RegCard from "./regCard.js";
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

function App() {


  // Variable For Storage
  const [getUsername, SetUsername] = useState();
  const [getPassword, setPassword] = useState();
  const navigate = useNavigate()
  const HandleFormSubmit = (e) => { 
    e.preventDefault();
    if(Boolean(getUsername) && Boolean(getPassword)){
      const formdata = new FormData();
      formdata.append("username", getUsername);
      formdata.append("password", getPassword);
        axios.post("https://login.vortexictmumbai.com/vortexdash/login.php",formdata).then(response => { 
          const arrayDataVortex = response.data ? response.data[0] : null;
          if(arrayDataVortex.status === true){
            const objectdata = { 
              sno : arrayDataVortex.details ? arrayDataVortex.details[0].uid : null,
              name : arrayDataVortex.details ? arrayDataVortex.details[0].name : null
             }
             if(Boolean(objectdata)){
              navigate("RegCard", { replace:false, state: objectdata  })

             }
          }else if(arrayDataVortex.status === false){
            alert("invalid Username or Password");
          }else{
            alert("Something Went Wrong Please Contact admin");
          }
        }).catch(err => { 
          console.log(err)
        })
    }
  }

  return (
    <>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-gH2yIJqKdNHPEq0n4Mqa/HGKIhSkIHeL5AyhkYV8i59U5AR6csBvApHHNl/vI1Bx"
        crossOrigin="anonymous"
      />
      <div className="vh-100 d-flex justify-content-center align-items-center ">
        <div className="col-md-5 p-5 shadow-sm border rounded-5 border-primary bg-white">
          <h2 className="text-center mb-4 text-primary">Login Form</h2>
          <form onSubmit={HandleFormSubmit}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control border border-primary"
           
                required
                onChange={(e) => { SetUsername(e.target.value); }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control border border-primary"
            
                onChange={(e) => { setPassword(e.target.value) }}
                required
              />
            </div>
            <div className="d-grid">
              {/* <Link to="/RegCard"> */}
              <button className="btn btn-primary" type="submit">
                Login
              </button>
            
            </div>
          </form>
          <div className="mt-3">
            <p className="mb-0  text-center">
              Don't have an account?{" "}
              <a href="https://pages.razorpay.com/pl_NC9vKM2gU1tfWf/view" className="text-primary fw-bold">
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>


      {/* <RegCard /> */}
    </>

  );
}

export default App;
