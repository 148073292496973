import ChemFlow from './elogo/PRODIGY SERIES/CHEMFLOW.png';
import jr from './elogo/PRODIGY SERIES/JR.png';
import sr from './elogo/PRODIGY SERIES/SR.png';
import py from './elogo/PRODIGY SERIES/PAPYRUS.png';
import cd from './elogo/PRODIGY SERIES/cd.jpeg';
import cos from './elogo/PHARMA SERIES/COSMECURE.png';
import gen from './elogo/PHARMA SERIES/GENE AUCTION.png';
import ana from './elogo/PHARMA SERIES/ANALYZE.png';
import blu from './elogo/PHARMA SERIES/BLUEPRINT.png';
import mc from './elogo/PHARMA SERIES/MOOT COURT.png';
import IG from './elogo/GENERAL SERIES/IGNITE.png';
import ud from './elogo/GENERAL SERIES/UD.png';
import UP from './elogo/GENERAL SERIES/UPBIZ.png';
import AD from './elogo/GENERAL SERIES/ADVENT.png';
import SDP from './elogo/GENERAL SERIES/SDP.png';
import './style.css';
import Rheader from './elogo/Rheader';
import Rfooter from './elogo/Rfooter';
import {useLocation} from "react-router-dom";
function RegCard() {
    const location = useLocation();
    console.log(location.state.sno)
    return (
     
        <>
           
            <link
                href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                rel="stylesheet"
                id="bootstrap-css"
            />
            <Rheader name={location.state.name  } />
            {/*---- Include the above in your HEAD tag --------*/}
            {/* Team */}
            <section id="team" className="pb-5">
                <div className="container">
                    <h5 className="section-titlee h1"><b>VORTEX ICT 2024 Event Registration. </b>
                    </h5>
                    <h5 className="section-title h1">PRODIGY SERIES REGISTRATION </h5>
                    <div className="row">
                        {/* Team member 1 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={ChemFlow}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">ChemFlow</h4>
                                                <p className="card-text">
                                                    Experience a high-stakes auction as a consultant, making quick decisions to secure game-changing equipment for an instant production process upgrade.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">ChemFlow</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:v.chemflow@gmail.com">Contact :- v.chemflow@gmail.com</a></p>
                                                <p>Team Size :- Min = 2, Max = 4</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/coZ6VyDpy976GfJk9" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 2 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={jr}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">JR. IDP</h4>
                                                <p className="card-text">
                                                    Jr IDP: First and second-year teams tackle real industrial challenges, submitting abstracts in Round 1 and presenting detailed, practical solutions in Round 2.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">JR. IDP</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:v.jridp@gmail.com">Contact :- v.jridp@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 3</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/xMT4z32kawivFdQ77" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member  3*/}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={sr}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">SR IDP 2024</h4>
                                                <p className="card-text">
                                                    IDP at Vortex: Industry-defined problems, real solutions. Proud legacy, expert analysis. Join us at Chemfest, ICT's unique event
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">SR IDP 2024</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:vortex.sridp@gmail.com">Contact :- vortex.sridp@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 3</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="http://sridp.vortexictmumbai.com/" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 4 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={py}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">PAPYRUS</h4>
                                                <p className="card-text">
                                                    A showcase of brilliance! Postgraduates present papers, undergraduates offer critical literature reviews. Top students, distinguished faculty judges.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">PAPYRUS</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:vortex.papyrus@gmail.com">Contact :- vortex.papyrus@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 3</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/9z21wwoAgWdzs4q47" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 5 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={cd}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">CodaThon</h4>
                                                <p className="card-text">
                                                    Code-A-Thon: Elevate coding skills for chemical engineering challenges with GDSC VJTI collaboration.                                      </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">CodaThon</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:chemcodes.vortex@gmail.com">Contact :- chemcodes.vortex@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 1</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/wRjC9oNPPzftntJk8" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                    </div>
                </div>
            </section>
            {/* Team */}
            {/* Team pharma  */}
            <section id="team" className="pb-5">
                <div className="container">
                    <h5 className="section-title h1">PHARMA SERIES REGISTRATION</h5>
                    <div className="row">
                        {/* Team member 1 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={cos}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">COSMECURE</h4>
                                                <p className="card-text">
                                                    Innovate in cosmetics! Formulate your product based on judges' statements in three knowledge-testing rounds, with a hands-on final experiment for find winner.                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">COSMECURE</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:cosmecure.vortex11@gmail.com">Contact :-cosmecure.vortex11@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 2</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSftsgLQr-DjDJhajHjnDsqHuYhlxs9iTpXqsjCC4Dfv3yin_g/viewform?usp=sf_link" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 2 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={gen}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">GENE AUCTION</h4>
                                                <p className="card-text">
                                                    Biotech Survival: No biology background required! Auction genes using ATP as currency, adapt and survive in a challenging world.                      </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">GENE AUCTION</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:geneauction.vortex11@gmail.com">Contact :- geneauction.vortex11@gmail.com</a></p>
                                                <p>Team Size :- Min = 2, Max = 2</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/o28Q5WgAiZWmztb56" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">

                                                        <button type="button" className="btn btn-secondary" data-mdb-ripple-init="">
                                                            Offline Quiz
                                                        </button>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member  3*/}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={ana}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">ANALYZE</h4>
                                                <p className="card-text">
                                                    Test your analytical skills in 3 rounds: Crack molecules using NMR/MASS SPECTROSCOPY and other tools' data, then present your analysis to the judges                        </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">ANALYZE</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:analyze.vortex11@gmail.com">Contact :- analyze.vortex11@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 2</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/KHnFrXLR9rdxrKB87" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/KHnFrXLR9rdxrKB87" >
                                                            <button type="button" className="btn btn-secondary" data-mdb-ripple-init="">
                                                                Take Quiz
                                                            </button>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 4 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={blu}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">BLUEPRINT</h4>
                                                <p className="card-text">
                                                    Redesign industry/pharmacy layouts! Design a production facility based on a given problem in three rounds: quiz, abstract submission, and presentation.                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">BLUEPRINT</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:blueprintvortex11@gmail.com ">Contact :- blueprintvortex11@gmail.com </a></p>
                                                <p>Team Size :- Min = 1, Max = 2</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/bHvxmQyAv8CZkvz27" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 5 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={mc}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">MOOT COURT</h4>
                                                <p className="card-text">
                                                    Exciting regulatory debate event! Three rounds: Quiz, written argument submission, and presenting arguments in front of judges                                      </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">MOOT COURT</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:mootcourt.vortex11@gmail.com">Contact :- mootcourt.vortex11@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 2</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/7A94aLo8aHgsinJA7" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                    </div>
                </div>
            </section>
            {/* Team */}
            {/* Team general*/}
            <section id="team" className="pb-5">
                <div className="container">
                    <h5 className="section-title h1">GENERAL SERIES REGISTRATION</h5>
                    <div className="row">
                        {/* Team member 1 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={IG}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">IGNITE</h4>
                                                <p className="card-text">
                                                    Ignite ICT: Mumbai's rapid-fire showcase event featuring diverse topics in just 5 minutes with 20 slides, keeping audience on the edge of their seats at Vortex.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">IGNITE</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:ictvortexignite@gmail.com">Contact :-ictvortexignite@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max =1</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdYmlZdDMuT5siM_oTGF8W-Un3_6vrVK6lpRnhdcn__3mRx1Q/viewform?usp=sf_link" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdYmlZdDMuT5siM_oTGF8W-Un3_6vrVK6lpRnhdcn__3mRx1Q/viewform?usp=sf_link" >
                                                            <button type="button" className="btn btn-secondary" data-mdb-ripple-init="">
                                                                Take Quiz
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 2 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={ud}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">UltiMate DeteCtives</h4>
                                                <p className="card-text">
                                                    Thrilling mystery hunt—decode riddles, find secrets, and follow clues with your team. No prior knowledge needed.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">UltiMate DeteCtives</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:vortexultimatedetectives@gmail.com">Contact :- vortexultimatedetectives@gmail.com</a></p>
                                                <p>Team Size :- Min = 3, Max = 3</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/8vyXuHZkjg2bjLk47" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member  3*/}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={UP}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">UpBiz</h4>
                                                <p className="card-text">
                                                    UpBiz: Pitch your product or business idea, connect with industrial experts, and get mentored for a chance to win prizes. Turn your ideas into success!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">UpBiz</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:upbiz.vortex@gmail.com">Contact :- upbiz.vortex@gmail.com</a></p>
                                                <p>Team Size :- Min = 2, Max = 4</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/gWVKwaUq9NdYQxkUA" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 4 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={AD}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">AdVent</h4>
                                                <p className="card-text">
                                                    Advent: Unleash creativity in advertising through posters and reels. Evaluate based on lasting impressions—an arrival of imagination and artistry.                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">AdVent</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:vortexadvent@gmail.com">Contact :- vortexadvent@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 4</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/s2fPLuhnen9rFaEF6" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                        {/* Team member 5 */}
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="image-flip">
                                <div className="mainflip flip-0">
                                    <div className="frontside">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <p>
                                                    <img
                                                        className=" img-fluid"
                                                        src={SDP}
                                                        alt="card"
                                                    />
                                                </p>
                                                <h4 className="card-title">SOCIAL DEFINED PROBLEM</h4>
                                                <p className="card-text">
                                                    Social Design Challenge: Solve societal issues in General and Pharma Series. Pitch meaningful solutions to judges for positive impact!                                     </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="backside">
                                        <div className="card">
                                            <div className="card-body text-center mt-4">
                                                <h4 className="card-title">SOCIAL DEFINED PROBLEM</h4>
                                                <p className="card-text">
                                                    Register now for an exclusive experience,
                                                </p>
                                                <p><a href="mailto:vortex11.sdp@gmail.com">Contact :- vortex11.sdp@gmail.com</a></p>
                                                <p>Team Size :- Min = 1, Max = 3</p>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/9vQKWzZRb2MSo6LJ9" >
                                                            <button type="button" className="btn btn-primary" data-mdb-ripple-init="">
                                                                Register Here
                                                            </button>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="https://forms.gle/9vQKWzZRb2MSo6LJ9" >
                                                            <button type="button" className="btn btn-secondary" data-mdb-ripple-init="">
                                                                Take Quiz
                                                            </button>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./Team member */}
                    </div>
                </div>
            </section>
            {/* Team */}
            <Rfooter />
        </>

    );
}

export default RegCard;
